import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const Entry = () => {
  const { domain } = useParams();

  useEffect(() => {
    if (domain) {
      localStorage.setItem("domain", domain);
      window.location.href = "/login";
    }
  }, [domain]);

  return <>Conectando {domain}</>;
};

export default Entry;
