import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import NewPassword from "../pages/NewPassword";
import RecoverPassword from "../pages/RecoverPassword";
import Register from "../pages/Register";
import Success from "../pages/Success";
import Entry from "../pages/Entry";

export function AppRouter() {
  return (
    <Routes>
      <Route path="entry/:domain" element={<Entry />} />
      <Route path="success" element={<Success />} />
      <Route path="login" element={<Login />} />
      <Route path="register" element={<Register />} />
      <Route path="recover-password" element={<RecoverPassword />} />
      <Route path="new-password/:hash" element={<NewPassword />} />
      <Route path="logout" element={<Logout />} />
    </Routes>
  );
}

export function AppRouterUnknown() {
  return (
    <Routes>
      <Route path="entry/:domain" element={<Entry />} />
    </Routes>
  );
}
