import { CssBaseline } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeTemplate } from "./components/templates/ThemeTemplate";
import { AppRouter, AppRouterUnknown } from "./Routes/AppRouter";
import "./styles/global.css";

function App() {
  if (!localStorage.getItem("domain")) {
    return <AppRouterUnknown />;
  }

  return (
    <div className="App">
      <CssBaseline />
      <ThemeTemplate>
        <AppRouter />
      </ThemeTemplate>

      <ToastContainer />
    </div>
  );
}

export default App;
