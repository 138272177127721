import React, { useEffect } from "react";
import { AUTH_USER_TOKEN } from "../constants/auth";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem(AUTH_USER_TOKEN);
    window.location.href = "/login";
  }, []);

  return <>Saindo...</>;
};

export default Logout;
