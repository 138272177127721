import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Link, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Button from "../components/atoms/Button";
import TextField from "../components/molecules/TextField";
import AuthTemplate from "../components/templates/AuthTemplate";
import { AUTH_USER_TOKEN } from "../constants/auth";
import { login } from "../services/auth";
import useApp from "../hooks/queries/useApp";

const schema = yup
  .object({
    email: yup.string().email("E-mail inválido").required("Digite seu e-mail"),
    password: yup
      .string()
      // .min(6, "A senha deve conter 6 ou mais caracteres")
      .required("Digite sua senha"),
  })
  .required();

const Login = () => {
  let navigate = useNavigate();

  const { app } = useApp();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const onSubmit = (data) => {
    setIsSubmitted(true);
    login(app?._id, data.email, data.password)
      .then((res) => {
        if (res && res.data && res.data.access_token) {
          localStorage.setItem(AUTH_USER_TOKEN, res.data.access_token);
          window.location.href = "/success";
          console.log("Login efetuado com sucesso!");

          const KEY = "domain";

          window.location.href = `https://${localStorage.getItem(
            KEY
          )}.appzei.com/auth?token=${res.data.access_token}`;

          localStorage.removeItem(KEY);
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          setLoginError(true);
        }
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };
  return (
    <AuthTemplate>
      <Box
        component="form"
        sx={{ textAlign: "center" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ mb: 2 }}>
          <Typography variant="h7" color="primary">
            Conecte-se
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            name="email"
            control={control}
            label="E-mail"
            placeholder="Digite seu e-mail"
            error={errors.email}
          />
        </Box>
        <Box sx={{ mb: 1 }}>
          <TextField
            type="password"
            name="password"
            control={control}
            label="Senha"
            placeholder="Digite sua senha"
            error={errors.password}
          />
        </Box>
        <Box sx={{ mb: 2, textAlign: "right" }}>
          <Link
            to="/recover-password"
            underline="none"
            sx={{ fontWeight: 500 }}
            component={RouterLink}
            color="primary"
          >
            Esqueci minha senha
          </Link>
        </Box>

        <Box sx={{ display: " flex", mt: 1 }}>
          <Box sx={{ mb: 2, width: "100%" }}>
            <Button type="submit" sx={{ width: "100%" }} disabled={isSubmitted}>
              Entrar
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            mb: 2,
            display: " flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link
            to="/register"
            underline="none"
            sx={{ fontWeight: 500 }}
            component={RouterLink}
          >
            Criar conta
          </Link>
        </Box>

        {loginError && (
          <Box sx={{ mt: 1, textAlign: "center", width: "100%" }}>
            <Typography
              variant="body2"
              sx={{ color: "#d32f2f", fontWeight: "bold", fontSize: 12 }}
            >
              Não foi possível realizar o login. Verifique seu e-mail e senha.
            </Typography>
          </Box>
        )}
      </Box>
    </AuthTemplate>
  );
};

export default Login;
