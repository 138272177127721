import axios from "axios";
import { AUTH_USER_TOKEN } from "../constants/auth";

const apiAuth = axios.create({
  baseURL: "https://sllbgp5wl1.execute-api.us-east-1.amazonaws.com/prd/",
  withCredentials: false
});

// Add a request interceptor TOKEN
apiAuth.interceptors.request.use(async (config) => {
  const token = localStorage.getItem(AUTH_USER_TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default apiAuth;
