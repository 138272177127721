import { useQuery } from "react-query";
import { getByDomain } from "../../services/apps";

const useApp = () => {
  const appDomain = localStorage.getItem("domain");

  
  
  const { data, isLoading, error, refetch } = useQuery("app_" + appDomain, () =>
  getByDomain(appDomain)
  );

  const app = data?.data || null;

  const styles = app?.styles || [];

  return { app, styles, isLoading, error, refetch };
};

export default useApp;
