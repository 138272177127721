import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import Button from "../components/atoms/Button";
import TextField from "../components/molecules/TextField";
import AuthTemplate from "../components/templates/AuthTemplate";
import { newPassword } from "../services/auth";
import PasswordStrengthBar from "react-password-strength-bar";
import { green } from "@mui/material/colors";

const schema = yup
  .object({
    password: yup
      .string()
      .min(6, "A senha deve conter 6 ou mais caracteres")
      .required("Digite sua senha"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "As senhas não conferem"),
  })
  .required();

const NewPassword = () => {
  let navigate = useNavigate();
  let { hash } = useParams();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [password, setPassword] = useState("");
  const [isInvalidHash, setIsInvalidHash] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
    },
  });
  const onSubmit = (data) => {
    setIsSubmitted(true);
    newPassword(data.password, hash)
      .then((res) => {
        if (res && res.data) {
          navigate("/login");
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 401) {
          setIsInvalidHash(true);
        }
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };
  return (
    <AuthTemplate>
      <Box
        component="form"
        sx={{ textAlign: "center" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="h6"
            sx={{ color: green[700], fontWeight: "bold" }}
          >
            Nova senha
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            type="password"
            name="password"
            control={control}
            label="Senha"
            placeholder="Digite sua senha"
            error={errors.password}
            onChange={(e) => {
              setValue("password", e.target.value);
              setPassword(e.target.value);
            }}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <PasswordStrengthBar
            password={password}
            minLength={6}
            scoreWords={["fraco", "fraco", "razoável", "bom", "muito bom"]}
            shortScoreWord="curto demais"
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            type="password"
            name="confirmPassword"
            control={control}
            label="Confirmar senha"
            placeholder="Digite sua senha novamente"
            error={errors.confirmPassword}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <Button type="submit" sx={{ width: "100%" }} disabled={isSubmitted}>
            Confirmar
          </Button>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Link to="/login">Voltar ao login</Link>
        </Box>
      </Box>

      {isInvalidHash && (
        <Box sx={{ mt: 1, textAlign: "center", width: "100%" }}>
          <Typography
            variant="body2"
            sx={{ color: "#d32f2f", fontWeight: "bold", fontSize: 12 }}
          >
            Token inválido, solicite novamente a recuperação de senha
          </Typography>
        </Box>
      )}
    </AuthTemplate>
  );
};

export default NewPassword;
