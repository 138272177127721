import apiAuth from "../config/api-auth";

export const login = (app, email, password) => {
  return apiAuth.post("/auth/login", {
    app,
    email,
    password,
  });
};

export const register = (app, email, password, name) => {
  return apiAuth.post("/auth/register", {
    app,
    email,
    password,
    name,
  });
};

export const recoverPassword = (email) => {
  return apiAuth.post("/auth/recover-password", {
    email,
  });
};

export const newPassword = (password, hash) => {
  return apiAuth.patch("/auth/new-password", {
    password,
    hash,
  });
};
