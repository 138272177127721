import { Card, ThemeProvider, createTheme } from "@mui/material";
import { useState } from "react";
import useApp from "../../../hooks/queries/useApp";

const ThemeTemplate = ({ children }) => {
  const { app, styles } = useApp();

  const [currentStyle, setCurrentStyle] = useState("light");
  const style = styles.find((style) => style.type === currentStyle);

  if (!app) return <>Carregando...</>;

  const myTheme = createTheme({
    // Theme settings
    palette: {
      mode: style?.type,

      secondary: {
        main: style?.primaryColor,
      },
      secondary: {
        main: style?.secondaryColor,
      },
      background: {
        default: style?.backgroundColor,
      },
      text: {
        primary: style?.textColor,
      },
    },
  });

  return (
    <ThemeProvider theme={myTheme}>
      <Card
        sx={{
          borderRadius: 0,
          backgroundColor: style?.backgroundColor,
        }}
      >
        {styles.map((style) => (
          <button onClick={() => setCurrentStyle(style.type)}>
            {style.type}
          </button>
        ))}
        {children}
      </Card>
    </ThemeProvider>
  );
};

export default ThemeTemplate;
